.loginBackground {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("/public/login/fermeciel.jpg");
  background-size: cover;
  height: 100% !important;
  margin-top: -3rem;
}

.loginAlign {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 40px;
}

@import "assets/css/_colorsite";
//$color1
//$color2

.btnConnexion {
  background-color: $color2;
  color: black;
  border-radius: 15px;
}

.btnRetour {
  font-size: 21px;
}

.loginConnexion {
  font-size: 23px;
}
