.heightCarou {
    height: auto;
}
/* XS */
@media screen and (max-width: 575px){ 
     .heightCarou {
         height: 200px !important;
     }
} 
/* SM */
@media screen and (min-width: 576px) and (max-width: 767px){ 
     .heightCarou {
         height: 300px !important;
     }
} 
/* MD */
@media screen and (min-width: 768px) and (max-width: 991px){ 
     .heightCarou {
         height: 400px !important;
     }
} 
/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px){ 
     .heightCarou {
         height: 500px !important;
     }
} 
/* XL */
@media screen and (min-width: 1200px) and (max-width: 1499px) { 
     .heightCarou {
         height: 600px !important;
     }
} 
 /* XXL */
 @media screen and (min-width: 1500px) { 
     .heightCarou {
         height: 800px !important;
     }
 } 