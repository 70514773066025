.selectionDuMomentEnregistrer {
    margin-top: 38px;
}

.choixSavImgSelMoment {
    margin-bottom: -30px;
}

/* XS */
@media screen and (max-width: 575px){ 
    .phraseAccroche, .descriptionImageSelMoment, .phraseFondRouge, .adminProducteurAdresse {
        width: 338px !important;
        font-size: 18px !important;
    }
} 
/* SM */
@media screen and (min-width: 576px) and (max-width: 767px){ 
    .phraseAccroche, .descriptionImageSelMoment, .phraseFondRouge, .adminProducteurAdresse {
        width: 518px !important;
        font-size: 18px !important;
    }
} 
/* MD */
@media screen and (min-width: 768px) and (max-width: 991px){ 
    .phraseAccroche, .descriptionImageSelMoment, .phraseFondRouge, .adminProducteurAdresse {
        width: 618px !important;
        font-size: 18px !important;
    }
} 
/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px){ 
    .phraseAccroche, .descriptionImageSelMoment, .phraseFondRouge, .adminProducteurAdresse {
        width: 790px !important;
        font-size: 22px !important;
    }
} 
/* XL */
@media screen and (min-width: 1200px) { 
    .phraseAccroche {
        width: 800px;
    }
} 

.phraseAccroche {
    font-size: 2rem;
    margin-left: 0.3rem;
}

.phraseFondRouge {
    font-size: 1.8rem;
    width: 58rem;
    margin-left: 0.3rem;
}

.adminProducteurAdresse {
    max-width: 37rem !important;
}

.descriptionImageSelMoment {
    font-size: 1.5rem;
    width: 800px;
    margin-left: 0.3rem;
}

.inputOffre {
    margin-top: -6.5rem;
    width: 6rem;
    margin-left: 2.7rem;
}

.inputOffreClient {
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
    font-family: 'Roboto Condensed', sans-serif;
    line-height: 1.5rem;
    // margin-top: -4.9rem;
    width: 6rem;
    margin-left: -5.9rem;
    font-weight: bold;
    text-align: center;
    color: white;
    font-size: 1.2rem;
}

.grandeImageSelMoment {
    max-width: 800px;
    width: 100%;
    min-width: 370px;
}