.footer {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.legals {
    color: white !important;
    align-self: center;
}

.cFerme {
    font-size: inherit !important;
}


/* MD */
/* @media screen and (min-width: 768px) { 
    .cFerme {
        margin-bottom: -3px !important;
    }
}  */


.logoIdsvib {
    width: 50px;
    height: 50px;
}

/* XL */
@media screen and (min-width: 1200px) { 
    .customAdmin {
        margin-left: 24px !important;
    }
} 

/* XS */
@media screen and (max-width: 575px){ 
    .mtLegals {
        margin-top: 20px !important;
    }
} 

.customAdminBlack {
    padding-top: 13px !important; 
}

.infosFooterRouge {
    cursor: pointer !important;
}

.footerRedTitle {
    width: 280px;
}