@import 'assets/css/_colorsite';
//$color1
//$color2

.page-item.active .page-link {
    background-color: $color3;
    color: white;
    font-weight: bold;
}

.page-link {
    color: $color3;
}

.nextPrevious {
    color: $color5;
}