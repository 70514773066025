.bgAdmin {
    font-size: 1.5rem;
}

.containerAdmin {
    width: 90% !important;
}

.nav-item {
    padding-right: 10px;
    padding-left: 10px;
}

@import 'assets/css/_colorsite';

.voirDocument {
    color: $color3 !important;
    font-weight: bold;
    margin-left: 42px;
}

.bgNavAdmin {
    background-color: rgb(83, 83, 83);
}

.bgNavAdmin a:hover {
    background-color: #585858 !important;
}

.noHoverCC:hover {
    background-color: black !important;
}

.mhAdmin {
    min-height: 200px;
}

p {
    font-size: 1.5rem;
    font-weight: normal !important;
}

.title {
    font-size: 1.9rem;
}

.titleInterface {
    font-size: 2.8rem;
    color: white !important;
    font-weight: bold !important;
}

.fontCC {
    font-size: 40px;
}

ul.links {
    list-style-type: circle;
}

// ADMIN COULEUR GLOBALE
@import 'assets/css/_colorsite';
//$color1
//$color2

@mixin couleurAdmin {
    background-color: $couleurAdmin !important;
}

$couleurAdmin: $color3;

.adminNav a {
    color: white;
    font-size: 1.6rem;
    border: 1px solid white;
    // padding-right: 7rem !important;
    // padding-left: 7rem !important;
    text-align: center !important;
}

.adminSousNav a {
    color: $color5;
}

// html {
//     background-color: $couleurAdmin;
// }
.adminBackMenuColor {
    @include couleurAdmin;
}

.adminBackMenuWhite {
    @include couleurAdmin;
    color: white !important;
}

.jcsbColor {
    @include couleurAdmin;
}

.jcsbColorWhite {
    background-color: white !important;
}

#desktop .navColor {
    @include couleurAdmin;
}

#desktop .navColorWhite {
    background-color: white !important;
}

.headerAdminColor {
    @include couleurAdmin;
}

.headerAdminWhite {
    @include couleurAdmin;
}

// .bgAdmin {
//     background-color: $couleurAdmin !important;
// }
// .bgAdminWhite {
//     background-color: white !important;
// }

.containerAdminColor {
    background-color: $color7 !important;
    min-height: 100vh;
}

// .containerColorAdminColor {
//     background-color: $color7 !important;
// }

.containerColorAdminWhite {
    background-color: white !important;
}

.titleAdminColor {
    color: $couleurAdmin !important;
}

.titleAdminWhite {
    color: white !important;
}

.navTitleColor {
    color: $color5 !important;
}

.navTitleColor:hover {
    color: $couleurAdmin !important;
}

.navTitleWhite {
    color: white !important;
}

.navTitleWhite:hover {
    color: white !important;
    font-weight: bold !important;
}

.titlePageAdminColor {
    color: $color5 !important;
}

.titlePageAdminWhite {
    color: white !important;
}

// .adminNav a:hover {
//     // background-color: $color1;
//     color: $color3 !important;
//     font-weight: bold !important;
// }
// .bgAdmin {
//     background-color: white !important;
// }
// .containerAdmin {
//     background-color: white !important;
// }

.sousMenuNoir a {
    border-style: none;
}

// .sousMenuNoir a span:hover {
//     color: $color8 !important;
//     // background-color: $color6;
// }

.sousMenuNoir {
    background-color: $color6;
    width: 100%;
    margin-left: 0;
}

.sousMenuBlanc a:hover {
    color: $color3;
}

.dropdownClick {
    background-color: $color8;
}

.dropdownNotClick {
    background-color: $color3;
}

li {
    list-style: none;
}

// .dropdown-toggle:active {
//     background-color: rgb(0, 38, 255) !important; //Any color you want
//     }

//FLECHES ADMIN MENU
@mixin flecheBlancheAdmin {
    height: 23px;
    width: 23px;
    position: absolute !important;
    border: solid white;
    border-width: 0px 4px 4px 0;
    padding: 3px;
}
.sousMenuNoir a:hover {
    color: $color8 !important;
    background-color: $color6;
    .flecheDroiteBlancheAdmin {
        // @include flecheBlancheAdmin;
        // transform: rotate(-45deg);
        // -webkit-transform: rotate(-45deg);
        // height: 25px;
        // width: 25px;
        // position: absolute !important;
        border: solid $color8;
        border-width: 0px 4px 4px 0;
        // padding: 3px;
    }
    // .sousMenuCateg {
    //     display: block !important;
    // }
}

// .sousMenuNoir:hover .sousMenuCateg {
//     display: block;
// }

.categListe:hover + .sousMenuCateg {
    display: block;
}

.sousMenuCateg:hover {
    display: block;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

.flecheGaucheBlancheAdmin {
    @include flecheBlancheAdmin;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
$degre: 43deg;
.flecheBasBlancheAdmin {
    @include flecheBlancheAdmin;
    transform: rotate($degre);
    -webkit-transform: rotate($degre);
}
.flecheDroiteBlancheAdmin {
    @include flecheBlancheAdmin;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.adminSousNav a {
    text-align: left !important;
    padding-left: 43px !important;
}

/* XS */
@media screen and (max-width: 575px){ 
    .sousMenuBlanc a {
        font-size: 18px !important;
    }
} 
/* SM */
@media screen and (min-width: 576px) and (max-width: 767px){ 
    .sousMenuCateg {
        margin-left: 17.9rem !important;
    }
} 
/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px){ 
    .sousMenuCateg {
        margin-left: 38vw !important;
    }
} 

/* XL */
@media screen and (min-width: 1200px) and (max-width: 1779px) { 
    .sousMenuCateg {
        margin-left: 27vw !important;
    }
} 

/* XXL */
@media screen and (min-width: 1780px) { 
    .sousMenuCateg {
        margin-left: 22vw !important;
    }
} 

.sousMenuCateg {
    display: none;
    position: absolute;
    // margin-left: 22vw;
    width: 22.5rem;
}

.sousMenuCateg a {
    color: rgb(0, 0, 0);
}

.sousMenuCateg a:hover {
    color: $color8;
    background-color: white;
}

$margin: 20px;

@mixin marginsSousMenuCalc {
    @for $i from 1 to 6 {
        // @debug marginSousMenu#{$i};
        $margin: $margin + 45px + $i*2px;
        /* XS */
        .marginSousMenu#{$i} {
            margin-top: $margin;
        }
        // @debug $margin + 10px;;
    }
}

// @debug "TEST";

//ANNULLE LE DOUBLE DROPDOWN SUR PORC BOEUF VEAU
//(MENU NOIR) SUR TEL ET TABLETTE CAR EN TACTILE
//ON A PAS ACCÈS AU 2EME SOUS MENU
/* SM */
@media screen and (min-width: 992px){ 
    @include marginsSousMenuCalc;
}
/* MD */
@media screen and (max-width: 991px){ 
    .sousMenuCateg {
        margin-left: 0rem !important;
    }
    .categListe + .sousMenuCateg {
        display: block;
        position: relative;
        width: 100% !important;
    }
} 

.cadreBlancs, .cadreBlancs a {
    font-size: 1.3rem !important;
    height: 4rem;
    padding-top: 14px;
    width: 26.5rem;
}

/* XS */
@media screen and (max-width: 575px){ 
    .positionFlecheGauche {
        margin-left: 0rem !important;
        margin-top: 5px !important;
    }
} 

.positionFlecheGauche {
    margin-left: 2rem;
    margin-top: 5px !important;
}

.dropdown-toggle::after {
    display: none;
}