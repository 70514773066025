$color1:  #FAE8D9;
$color2: #f4d5c9ff;
$color3: #a32b22;
$color4: #802119;
$color5: #2a2a2a; //font
$color6: #282625; //noir gestion site dropdown navbar
$color7: #514343; //gris fond admin interface site
$color8: #EA6E6E;

.color1 {
    background-color: $color1;
}

.color2 {
    background-color: $color2;
}

.color3 {
    background-color: $color3;
}

.color3Text {
    color: $color3;
}

.color8 {
    background-color: $color8;
}
