$pulse : 2s infinite cubic-bezier(0.66, 0, 0, 1);

.pulseButton {

    border: none;
    border-radius: 3%;
    box-shadow: 0 0 0 0 rgba(244, 213, 201, 0.3);
    // background-color: #FCD9DA;
    cursor: pointer;
    -webkit-animation: pulse $pulse;
    -moz-animation: pulse $pulse;
    -ms-animation: pulse $pulse;
    animation: pulse $pulse;
}

$box-shadow: 0 0 0 45px rgba(255, 183, 183, 0);

@-webkit-keyframes pulse {to {box-shadow: $box-shadow;}}
@-moz-keyframes pulse {to {box-shadow: $box-shadow;}}
@-ms-keyframes pulse {to {box-shadow: $box-shadow;}}
@keyframes pulse {to {box-shadow: $box-shadow;}}