// assets/css/global.scss

// customize some Bootstrap variables
// $primary: darken(#428bca, 20%);

/* import what we need to override */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// @debug $grid-breakpoints;

/* set the overriding variables */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1780px,
);
$container-max-widths: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1780px,
);

.debugBootstrap {
    position: absolute;
}

// @debug $grid-breakpoints;

/* override the !default vars with the values we set above */
// @import "~bootstrap/scss/bootstrap";

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

.center {
    // display: block;
    margin-left: auto;
    margin-right: auto;
}

.lineH p {
    line-height: 1;
    margin-bottom: 8px;
    font-family: 'Myriad Pro Regular';
}

// .lovelyHome p {
//     font-family: "LovelyHome" !important;
//     color: #a32b22 !important;
// }

a:hover {
    text-decoration: none !important;
}

@font-face {
    font-family: 'Arial Nova Light';
    src: url('../fonts/arial-nova-light.ttf');
}

@mixin fontStyle {
    font-family: "Arial Nova Light" !important;
    letter-spacing: 1.3px !important;
    // font-size: 1.6rem !important;
    // transform: scaleY(0.5);
    // transform-origin: 0 0;
    color: rgb(42, 42, 42) !important;
}

.fontStyleFinal {
    @include fontStyle;
}

.fontStyleFinalAdminNav {
    @include fontStyle;
    font-weight: bold !important;
}

.noCss {
    background: none;
    border: none;
    margin: 0 !important;
    padding: 0 !important;
    text-align: left !important;
}

.noCss:focus {
    outline: none;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 999 !important;
}

.stickyTel {
    top: 0px;
}

.radioOption {
    vertical-align: top !important;
    margin-top: 5px;
}

.hiddenImportant {
    visibility: hidden !important;
}