.euro {
    font-size: 17px;
}

.prixPrdPan {
    margin-left: 5%;
}

.ml-4_5 {
    margin-left: 3%;
}

.texteRaye {
    text-decoration: line-through;
}