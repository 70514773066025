@media screen and (max-width: 768px) {
    .doubleLigneGauche,
    .doubleLigneDroite {
        padding: 0 !important;
        text-align: center;
    }
}
/* body {
    background-color: rgb(195, 195, 195);
} */

@media screen and (min-width: 768px) {
    .doubleLigneGauche {
        width: 50%;
        margin: 0 !important;
        text-align: right !important;
        font-size: 1rem;
    }
    .doubleLigneDroite {
        width: 50%;
        margin: 0 !important;
        text-align: left !important;
        font-size: 1rem;
    }
}

.bordureRouge {
    border: 2px solid;
    border-color: #a32b22;
    width: 67.5% !important;
}

.bordureRougeRadius {
    border: 2px solid;
    border-color: #a32b22;
    width: 67.5% !important;
    border-radius: 30px;
}

/* XS */
@media screen and (max-width: 575px){ 
    .bordureRougeRadius {
        width: 100% !important;
        margin-right: 2rem;
    }
} 

.lovelyHomeWhite {
    font-family: "LovelyHome";
    color: white;
    font-size: 2rem !important;
}

.verticalAlign {
    vertical-align: center !important;
    display: table-cell !important;
}

.maxWidth {
    max-width: 30rem;
}

.ptCustom {
    padding-top: 30px !important;
}

.ptCustomOdd {
    padding-top: 45px !important;
}

.mlAvDerPhrase {
    margin-left: -11px !important;
}

.ajouterPanierAccueil {
    position: absolute;
    margin-top: -2.5rem;
    margin-left: 26.5rem !important;
}

.ajouterPanierAccueilLeft {
    position: absolute;
    margin-top: -3rem;
    margin-left: 13rem !important;
}

.titreImagePrincipaleSelMoment {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 1.7rem;
    font-weight: bold !important;
}

.descriptionImagePrincipaleSelMoment {
    font-size: 1.5rem;
}

.producteur {
    font-size: 1.3rem;
}

/* SM */
@media screen and (max-width: 767px){ 
    .tailleImages {
        width: 100% !important;
    }
    .tailleOffre {
        width: 90px !important;
    }
    .inputOffreClient {
        font-size: 1rem !important;
        padding-left: 1rem !important;
    }
} 

/* XL */
@media screen and (min-width: 768px) and (max-width: 1779px){ 
    .tailleImages {
        width: 100% !important;
    }
} 

.tailleImages {
    width: 200px;
}

.tailleOffre {
    width: 110px;
}

.maxWPub {
    max-width: 800px !important;
}

.phraseFondRougeAccueil {
    font-size: 1.8rem !important;
    margin-top: 25px !important;
}

/* SM */
@media screen and (min-width: 1778px) and (max-width: 1818px){ 
    .specificWidthOffre {
        width: 105px !important;
    }
} 

/* XXL */
@media screen and (min-width: 1980px) { 
    .text-centerForXXXL {
        text-align: center !important;
    }
} 