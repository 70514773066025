th,
td {
    padding: 15px;
    padding-right: 110px;
    text-align: left;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

.test {
    background-color: red !important;
}

@media print {

    .myelement1 {
        background-color: red;
    }
    
}