@import 'assets/css/_colorsite';
//$color1
//$color2

.contactTitle {
    font-family: 'LovelyHome';
    color: $color4;
    margin-top: 2vh;
    text-align: center;
    font-size: 3.5em;
}

.contactLabel {
    font-weight: bold;

}

.contactInput {

    border: none;
    border-radius: 5px;
    height: 2em;
    padding: 0 30px;
}

.contactMessage {
    /* width:5%; */
    height: 15em;
    border:none;
    border-radius: 5px;
}

.contactButtonContainer {
    width:97.5%;
}

.sendButton {
    color:white;
    border: none;
    border-radius: 5px;
    padding: 3px 40px;
}

.contactTextContainer {
    margin-top: -31px;
}

.contactTextContainer h3 {

    font-weight: bold;
    text-align: center;
}

.contactTextContainer h2 {
    font-weight:1000;
}

.contactTextContainer p {
    font-style: italic;
    text-align:center;
}

#contactmw594{
    display:block;
}

#contactMw593 {
    display: none;
}

.contactMap {
    max-width: 100%;
}

@media screen and (max-width: 1840px) {
    .contactTextContainer {
        margin-top: 2vh;
    }

    .contactButtonContainer {
        justify-content: center !important;
        margin-left: 0 !important;
    }
}

/* XS */
@media screen and (max-width: 575px){ 
    .contactTextContainer p {
        font-size: 19px;
    }
} 

@media screen and (max-width: 991px) {

    .contactBigContainer {
        max-width: 100%;
    }
    .contactInput {
        width: 97.5%
    }

    .contactContainer {
        /* margin-left: 0 !important; */
        margin: 0 auto;

    }

    .contactNameContainer {
        padding-right: 0 !important;
    }

    .emailContainer {
        padding-top: 2vh;
    }
}

@media screen and (max-width: 524px) {
    #contactmw594{
        display:none;
    }
    
    #contactMw593 {
        display: block;
    }
}

.separationContact {
    border: 1px solid $color4;
    width: 100%;
}

/* XL */
// @media screen and (min-width: 1900px) { 
//     .separationWidth {
//         margin-right: -7rem;
//         margin-left: -7rem;
//     }
// } 

@mixin contactGeneralSize {
    font-size: 1.75rem;
    font-weight: bold !important;
    font-style: normal !important;
}

.contactSize {
    @include contactGeneralSize  
}

.contactTel {
    font-size: 2.5rem !important;
    @include contactGeneralSize
}

.contactAdresse {
    color: $color4;
    font-size: 1.85rem !important;
}

.messageHeight {
    height: 10rem;
}