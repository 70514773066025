.telMobile {
    font-size: 1.4rem;
}

.pictoHeader {
    padding: 0;
}

.borderRose {
    border-bottom: 10px solid #f6a9a7;
}

.headerTextContainer {
    position: absolute;
}

.curvedText {
    margin-top: 1.5vw;
}

.headerTextContainer h5 {
    font-size: 1.1vw !important;
}

.freeShipping {
    text-decoration: underline;
    font-size: 1.5vw;
}

.bgLigne {
    background-image: url(/public/img/Bandeau-3Couleurs.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 55px;
    width: 100%;
    z-index: 999;
}

.header__carousel {
    top: 7rem;
    margin-bottom: 7rem
}

.carousel-control-prev {
    z-index: 998;
    /* height:12vh ; */
}

.carousel-control-prev-icon {
    margin-top: 140%;

}

.carousel-control-next {
    z-index: 998;
}

.carousel-control-next-icon {
    margin-top: 140%;
}

.left-8 {
    left: -8vw;
}

.horaires {
    width: 100% !important;
}

.horaires::before {
    content: url(/public/img/Picto-Horaire.png);
    position: relative;
    bottom: -2vh;
    left: -0.5vw;
}

.contact {
    width: 100% !important;
}

.contact::before {
    content: url(/public/img/Picto-Contact.png);
    position: relative;
    bottom: -0.5vh;
    left: -0.5vw;
}

.telephone {
    width: 100% !important;
}

.telephone::before {
    content: url(/public/img/Picto-Telephone.png);
    position: relative;
    bottom: -0.5vh;
    left: -0.5vw;
}

.adresse {
    width: 100% !important;
}

.adresse::before {
    content: url(/public/img/Picto-Adresse.png);
    position: relative;
    bottom: -0.5vh;
    left: -0.5vw;
}

.accueil {
    width: 100% !important;
}

.accueil::before {
    content: url(/public/img/Picto-Accueil.png);
    position: relative;
    bottom: -0.5vh;
    left: -0.5vw;
}

.nouveautes {
    width: 100% !important;
}

.nouveautes::before {
    content: url(/public/img/Picto-Nouveaute.png);
    position: relative;
    bottom: -0.5vh;
    left: -0.5vw;
}

.selection {
    width: 100% !important;
}

.selection::before {
    content: url(/public/img/Picto-Selection.png);
    position: relative;
    bottom: -0.5vh;
    left: -0.5vw;
}

.laboratoire {
    width: 100% !important;
}

.laboratoire::before {
    content: url(/public/img/Picto-Laboratoire.png);
    position: relative;
    bottom: -0.5vh;
    left: -0.5vw;
}

.compte {
    width: 100% !important;
    cursor: pointer !important;
}

.dropCompte {
    text-align: center;
    width: 15%;
}

.btnCompte {
    background-color: #111;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none;
}

.compte::before {
    content: url(/public/img/Picto-MonCompteS.png);
    position: relative;
    bottom: -0.5vh;
    left: -0.5vw;
}

/* BARRE DE NAVIGATION horizontale (menu) */

.panier {
    width: 100% !important;
    margin-top: 17px !important;
}

.panierPosition {
    margin-top: -28px;
}

.panierContainer {
    position: relative;
}

/* LG */
@media screen and (min-width: 992px) and (max-width: 1779px) { 
    .panierPosition {
        margin-left: 3rem !important;
    }
} 

/* XXL */
@media screen and (min-width: 1780px) { 
    .panierPosition {
        margin-left: 7rem !important;
    }
} 

/* XS-MD */
@media screen and (max-width: 991px){ 
    .panierNombre {
        margin-left: -0.7rem !important;
    }
    .panierPosition {
        margin-left: 2.1rem !important;
    }
} 

.panierPlaceNombre {
    position: absolute;
    /* margin-right: -90px !important; */
    left: 99px !important;
    bottom: 22px !important;
    color: rgb(255, 255, 255);
}

.pointer {
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .pictoHeader {
        padding: 0rem;
    }
}

@media screen and (min-width: 993px) {
    .accueil {
        width: 15% !important;
    }

    .nouveautes {
        width: 15% !important;
    }

    .selection {
        width: 25% !important;
    }

    .laboratoire {
        width: 15% !important;
    }

    .compte {
        width: 15% !important;
    }

    .panier {
        width: 15% !important;
    }

    .panierPosition {
        margin-left: 7rem;
    }
}

/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px){ 
    .panierPlaceNombre {
        left: 45px !important;
        position: absolute !important;
    }
} 
/* XL */
@media screen and (min-width: 1200px) and (max-width: 1779px) { 
    .panierPosition {
        margin-left: 4rem;
    }
    .panierPlaceNombre {
        left: 45px !important;
    }
} 
/* XXL */
@media screen and (min-width: 1780px) { 
    .panierNombre {
        margin-left: 61px !important;
    }  
} 

/* Sidenav */

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.1s;
    padding-top: 60px;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 3.5vw;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
}

@media screen and (min-width: 700px) {
    .sidenav a {
        font-size: 2.5vw;
    }
}

/* ~~ XS */
@media screen and (max-width: 699px){ 
    .fontNavAccountSize {
        font-size: 3.5vw !important;
    }
} 

/* ~~ MD */
@media screen and (min-width: 700px) and (max-width: 991px){ 
    .fontNavAccountSize {
        font-size: 2.5vw !important;
    }
}

.headerContainer {
    margin-top: -8px;
}