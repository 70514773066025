.progress {
    border-radius: 30px;
}

.valid {
    opacity: 0.5;
}

.panierProductContainer {
    margin-top:10%;
    background-color: white;
    border: 1px solid #802119;
    height:150px;
    width:30px;
    min-width:250px;

}

.imagePanier {
    max-height: 90px;
    margin-left: 90px;
    margin-top: -14px;
}

.fontSize {
    font-size: 1.5rem;
}

.noColorBtn {
    background-color: #11ffee00;
    border: none;
}

/* XL */
@media screen and (min-width: 1200px) and (max-width: 1500px) { 
    .quantite {
        margin-left: 40px;
    }
    .prixTotal {
        margin-left: 2rem;
    }
} 

@media screen and (min-width: 1501px) { 
    .quantite {
        margin-left: 40px;
    }
    .prixTotal {
        margin-left: 3rem;
    }
} 

.horizontalLine {
    width:100%;
    text-align:left;
    margin-left:0;
}

.totalFinal {
    font-size: 1.3rem;
    color: #A32B22;
}

/* XS */
@media screen and (max-width: 575px){ 
    .telPanQt {
        font-size: 13px !important;
        min-width: 59px !important;
    }
    .sizePricesTel {
        font-size: 16px !important;
    }
    .sizeExposantsTel {
        font-size: 9px !important;
        vertical-align:super;
    }
} 

/* LG */
@media screen and (min-width: 768px){ 
    .noBreakLinesPan {
        white-space: nowrap;
    }
} 

/* SM */
@media screen and (min-width: 576px) and (max-width: 767px){ 
    .telPanQt {
        font-size: 13px !important;
        min-width: 59px !important;
    }
} 

.lieuChoisiPanier {
    font-size: 1.2rem !important;
}

// @mixin barre {
//     border-radius: 30px;
//     width: 350px;
//     height: 13px;
//     -webkit-appearance: none;
//     appearance: none;
// }

// @mixin rondeur {
//     border-radius: 30px;
// }

// @mixin opacityBar {
//     opacity: 0.5 !important;
// }

// @mixin couleurFondBarre {
//     background: #E6E6E6 !important;
// }

// .textVal {
//     opacity: 0.4 !important;
// }

// progress {
//     @include barre;
//     @include couleurFondBarre;
// }

// .progressVal {
//     @include barre;
//     @include opacityBar;
// }

// progress::-moz-progress-bar {
//     @include rondeur;
//     background: #08b200;
// }

// .progressVal::-moz-progress-bar {
//     @include rondeur;
//     @include couleurFondBarre;
//     @include opacityBar;
// }

// progress::-webkit-progress-bar {
//     @include couleurFondBarre;
//         // background: blue !important;
// }

// .progressVal::-webkit-progress-value{
//     @include couleurFondBarre;
// }