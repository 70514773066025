.rowColorEven {
    background-color: rgb(241, 241, 241) !important;
}

.rowColorOdd {
    background-color: white !important;
}

.white {
    background-color: white;
    border-top: none !important;
}

.width {
    width: 3px !important;
}

.tableHead {
    background-color: #A32B22 !important;
    color: white !important;
}

.mesCommandesThead th {
    border-right: 2px solid white !important;
}

.borderNone {
    border: none !important;
}

.marginTopCustomTextGauche {
    margin-top: 40px !important; 
}

.marginTopCustom {
    margin-top: 80px !important;
}

.detailImpression {
    width: 7rem !important;
    text-align: center;
}