.laboratoryTitle {
    font-size: 3.5em;
}

/* XS-SM */
@media screen and (max-width: 768px) {
    .doubleLigneGauche,
    .doubleLigneDroite {
        padding: 0 !important;
        text-align: center;
    }
    .carouLaboFlecheGauche {
        margin-right: -40px !important;
        margin-top: -6px !important;
    }
    .carouLaboFlecheDroite {
        margin-left: -40px !important;
        margin-top: -6px !important;
    }
}

/* MD */
@media screen and (min-width: 768px) {
    .doubleLigneGauche {
        width: 50%;
        margin: 0 !important;
        text-align: right !important;
        font-size: 1rem;
    }
    .doubleLigneDroite {
        width: 50%;
        margin: 0 !important;
        text-align: left !important;
        font-size: 1rem;
    }
}

.faitMaison {
    margin-left: 25rem;
}

.carouLaboFlecheGauche {
    margin-right: -60px;
}

/* XL */
@media screen and (min-width: 1200px) { 
    .carouLaboFlecheGauche {
        margin-right: 190px !important;
        margin-top: -6px !important;
    }
    .carouLaboFlecheDroite {
        margin-left: 190px !important;
        margin-top: -6px !important;
    }
} 

/* XXL */
@media screen and (min-width: 1780px) { 
    .carouLaboFlecheGauche {
        margin-right: 230px !important;
        margin-top: -6px !important;
    }
    .carouLaboFlecheDroite {
        margin-left: 230px !important;
        margin-top: -6px !important;
    }
    .heightCarou {
        height: 32rem !important;
    }
} 