.pdfMr {
    margin-right: 8rem;
}

// .bonCommandeTable {

//     th,
//     td {
//         padding: 15px;
//         padding-right: 110px;
//         text-align: left;
//     }

//     tr:nth-child(even) {background-color: #f2f2f2;}

// }