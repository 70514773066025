.detailedProductContainer {
    border: 1px solid #a32b22;
    border-top: 15px solid #a32b22;
}

.productImage {
    max-width: 200px;
    max-height: 200px;
    min-width: 180px;
    min-height: 180px;
    overflow: hidden;
}

.productThumbnailContainer {
    max-width: 200px;
    margin: auto;
}

.productThumbnail {
    width: 50px;
    height: 50px;
    border: 1px solid #a32b22;
}

.detailedProductTitle {
    color: #a32b22;
    font-weight: 900;
    border-bottom: 2px solid #a32b22;
}

.descriptionAndInfosContainer {
    height: 85%;
}

.descriptionText {
    font-weight: 700;
    font-size: 0.9em;
}

.productInformations,
.productLastInformation {
    font-size: 0.8em;
    font-weight: 600;
    border-top: 1px solid #a32b22;
}

.detailedProductPicto {
    width: 30px;
    height: 30px;
    vertical-align: bottom;
}

.productLastInformation {
    border-bottom: 1px solid #a32b22;
}

.detailedProductPriceContainer {
    border: 3px solid #802119;
    border-radius: 15px;
    width: 93px;
    max-height: 80px;
    background-color: white;
    position: relative;
    min-width: 93px;
}

.priceMaxHeight {
    max-height: 150px;
}

.addButton {
    color: white;
    border: none;
    border-radius: 5px;
    padding: 3px 12px;
    background-color: #a32b22;

}

.productContentImage {
    width: 200px;
    margin: 5px;
    border: 1px solid #a32b22;
    position: relative;

}

.contentProductCaption {
    position: absolute;
    bottom: 0;
    font-size: 0.8em;
    font-weight: 600;
    font-family: 'Myriad Pro Semibold';
}

.choixQuantite {
    width: 9.5rem;
    font-size: 14px;
}

.reduireFont {
    font-size: 1rem;
}

.miniFont {
    font-size: 1.2rem;
}

.euroProd {
    font-size: 0.6em;
    font-weight: 900;
    /* margin-top: -1rem !important; */
}

.priceProdDetail {
    font-size: 20px;
}

.nonRemise {
    font-size: 1rem;
}

/* XS */
@media screen and (max-width: 575px){ 
    .titreRougePage {
        font-size: 2.5rem !important;
    }
} 
/* SM */
@media screen and (min-width: 576px) and (max-width: 767px){ 

} 
/* MD */
@media screen and (min-width: 768px) and (max-width: 991px){ 

} 
/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px){ 

} 
/* XL */
@media screen and (min-width: 1200px) and (max-width: 1779px) { 

} 
/* XXL */
@media screen and (min-width: 1780px) { 

} 
