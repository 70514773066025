@font-face {
    font-family: 'LovelyHome';
    src: url('../fonts/LovelyHome.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro Semibold';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/MYRIADPRO-SEMIBOLD.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/MYRIADPRO-REGULAR.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro Black';
    src: url('../fonts/Myriad-Pro-Black.otf');
}

@font-face {
    font-family: 'Myriad Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/MYRIADPRO-BOLD.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro Italic';
    src: url('../fonts/Myriad\ Pro\ Italic.ttf');
}

@font-face {
    font-family: 'Futura Std';
    src: url('../fonts/FuturaStd-Book.otf');
}

/* POUR CKEDITOR  */
@font-face {
    font-family: "normal";
    src: url('../fonts/MerriweatherSans-VariableFont_wght.ttf');
}

.LovelyHome {
    font-family: "LovelyHome";
    color: #a32b22 !important;

}

.fontNormal {
    font-family: 'normal';
}

.myriadSemiBold {
    font-family: 'Myriad Pro Semibold';
}

.myriadRegular {
    font-family: 'Myriad Pro Regular' !important;
}

.myriadBold {
    font-family: 'Myriad Pro Bold';
}

.myriadItalic {
    font-family: 'Myriad Pro Italic';
}

.myriadBlack {
    font-family: 'Myriad Pro Black';
}

.futuraStd {
    font-family: 'Futura Std';
}

.lineHeight1em {
    line-height: 1em;
}

/* XL */
@media screen and (min-width: 1200px) { 
    .lineHeight1em {
        line-height: 0.5em;
    }
} 
.textLeft {
    text-align: left;
}

/* body p {
    font-family: 'Myriad Pro Semibold';
} */

body h4 {
    font-family: 'Myriad Pro Black';
}

h2 {
    font-size: 4rem !important;
}

.bgRouge {
    background-color: #a32b22 !important;
}

.bgBordeaux {
    background-color: #802119 !important;
}

@import 'assets/css/_colorsite';

.bgBleuCiel {
    background-color: #ceeffb;
}

.bgNoir {
    background-color: black !important;
}

.fontNoir {
    color: black;
}

.fontRouge {
    color: #a32b22 !important;
}

.fontBlanc {
    color: white;
}

.borderCarouselRose {
    border: solid 30px #FCD9DA;
}

.font09 {
    font-size: 0.9rem;
}

.font11 {
    font-size: 1.1rem;
}

.font12 {
    font-size: 1.2rem;
}

.font15 {
    font-size: 1.5rem;
}

.font23 {
    font-size: 2.3rem;
}

.font3-5 {
    font-size: 3.5em;
}

.exposant {
    vertical-align: super;
}

.padding0 {
    padding: 0;
}

.marginTop2vh {
    margin-top: 2vh;
}

.mt-4vh {
    margin-top: 4vh;
}

.mt-8vh {
    margin-top: 8vh;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    width: 30px !important;
    height: 30px !important;
}

.retourBtn {
    font-size: 1.2rem !important;
    padding: 12px !important;
    margin-left: 20.5rem;
}

@import 'assets/css/_colorsite';

//CAROUSELS

//CAROUSEL LABO
@mixin fleche {
    margin-top: 3rem;
    height: 25px;
    width: 25px;
    position: absolute !important;
    border: solid $color3;
    border-width: 0px 7px 7px 0;
    padding: 3px;
}
.flecheGauche {
    @include fleche;
    margin-right: 13rem;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.flecheDroite {
    @include fleche;
    margin-left: 14rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

//CAROUSEL ACCUEIL GÉNÉRAL
@mixin flecheNoire {
    margin-top: 19rem;
    height: 25px;
    width: 25px;
    position: absolute !important;
    border: solid black;
    border-width: 0px 7px 7px 0;
    padding: 3px;
}
/* XS */
@media screen and (max-width: 991px){ 
    $marginTop: 1rem !important;
    .flecheGaucheNoire {
        margin-top: $marginTop;
        margin-left: -9rem;
    }
    .flecheDroiteNoire {
        margin-top: $marginTop;
        margin-right: -8.3rem;
        
    }
} 
/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px){ 
    $marginTop: 9rem !important;
    .flecheGaucheNoire {
        margin-top: $marginTop;
        margin-left: -9rem;
    }
    .flecheDroiteNoire {
        margin-top: $marginTop;
        margin-right: -8.3rem;
    }
} 
/* XL */
@media screen and (min-width: 1200px) { 

} 

.flecheGaucheNoire {
    @include flecheNoire;
    margin-right: -10rem;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.flecheDroiteNoire {
    @include flecheNoire;
    margin-left: -10rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.nobreakLines {
    white-space: nowrap;
}

.btn:focus, .btn.focus {
    outline: none !important;
    box-shadow: none !important;
}

.fontTitresMenuGauche {
    font-size: inherit !important;
}