.boutonRose {
    border-radius: 40px;
    font-size: 1.8rem;
}

.paddingCustomBtnContactAccueil {
    padding-right: 60px !important;
}

.paddingCustomBtnContactGeneral {
    padding-left: 90px !important;
}