.compteFa {
    transform: scale(8,8);
}

.texteBoutonGeneral {
    text-decoration: none !important;
    color: black !important;
    /* z-index: 9 !important; */
    font-size: 22px;
    font-weight: normal;
    margin-left: 23px;
    letter-spacing: -1px;
    padding-top: 20px !important;
}

.containerBtnGeneral {
    /* z-index: 1 !important; */
    height: 4rem;
    border-radius: 50px;
    box-shadow: 2px 2px 1px silver;
    min-width: 19rem;
    max-width: 40%;
    background-image: url("/public/img/logo.png");
    background-size: 88px;
    background-repeat: no-repeat;
    background-position: right 9px bottom 11px;;
}

@import 'assets/css/_colorsite';
//$color1
//$color2

.containerBtnSecondaire {
    height: 4rem;
    border-radius: 50px;
    box-shadow: 2px 2px 1px silver;
}