.homeMade {
    max-height:100px;
    max-width: 180px;
    min-width: 105px;
}

.homeMadeText {
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;

}

@import 'assets/css/_colorsite';

.sidebarLink a:hover {
    color: $color3;
    text-decoration: none;
}

.shippingText {
    color: #00c3f0;
    text-align:left;
    position: relative;
    font-size: 0.8em;
    margin-right: 0% !important;
}

.shippingTitle {
    position: absolute;

}

.shippingCubeContainer {

    align-self: flex-end;
    margin-left: 70%;
    position: absolute;
    top:0;
    margin-top: 0.5vh;

}

.shippingCube {

    max-height: 70px !important;
    margin-top: 1.5vh;
}

@media screen and (min-width:1385px) {
    .homeMadeText {
        text-align: right;
        max-width:58.33% !important;
        position: relative;
    }
}
 
.encarSelMoment {
    font-size: 0.9rem;
    font-weight: bold;
}

.encarSelCenter {
    transform: translate(0px,-3px) !important;
}

.encarSelMomentPlusRose {
    background-color: #ffdcc0 !important;
}

.normalBtn {
    border: none;
    height: 3rem;
    padding-top: 2px;
}

.loupeBouton {
    cursor: pointer;
    transform: scale(1);
    transition-property: transform;
    transition-duration: 400ms;
    &:hover {
        transform: scale(1.08);
    }
}