.containerBgColor {
    background-color: #F0F0F0;
}

.tableTopColor {
    background-color: #F0F0F0;
}

thead th {
    border-right: 1px solid black !important;
    vertical-align: middle !important;
}

@media screen and (max-width: 1420px){ 
    .containerSubmit {
        display: none !important;
    }
}

.containerSubmit:hover {
    border-radius: 10px;
}

.btnSubmit {
    padding: 0;
    border: none;
    background: none;
}

.rechercher {
    width: 620px;
    border: 1px solid;
    text-align: left;
    padding-left: 34px !important;
}

.rechercher:focus {
    outline: none !important;
}

.btnCRUD {
    font-size: 1rem;
    border-radius: 50px;
    padding: 10px;
    border: 1px solid;
    border-color: silver;
}

.btnCRUD:focus {
    outline: none !important; 
}

.search {
    width: 80% !important;
}

.case1 {
    width: 25%;
}
.case2 {
    width: 10%;
}
.case3 {
    width: 20%;
}
.case4 {
    width: 20%;
}
.case5 {
    width: 15%;
}

@import 'assets/css/colorsite';

.containerSubmit:hover {
    background-color: $color1;
    border-radius: 10px;
}

.borderless td, .borderless th {
    border-bottom: 2px solid white !important;
}

.tbodyColor tr {
  background-color: rgb(245, 245, 245) !important;  
}

.textesTableau {
    text-align: left !important;
    font-size: 1.3rem;
}

.textesShowAll {
    font-size: 1.3rem;
}

.prix {
    text-align: right !important;
}

.prixChildren {
    margin-right: 45px !important;
}

.parentTexte {
    width: 90px;
    margin: auto;
}

//titre des colonnes trop longs
@media screen and (max-width: 1199px){ 
    .fontIntitule {
        font-size: 17px !important
    }
} 
