.tableTr th {
    font-weight: bold !important;
    font-size: 1.5rem;
    background-color: rgb(221, 221, 221) !important;
    // border-right-style: none !important;
}

.noBorder {
    border-right-style: none !important;
}

// .borderHeight {
//     padding: 0 10px !important; 
//     border-right: 1px solid #000 !important; 
//     line-height: 10px !important; 
// }

.reset .resetPadding {

    /* XS */
    @media screen and (max-width: 575px) {

        th,
        td {
            padding: 5px;
        }
    }

    /* SM */
    @media screen and (min-width: 576px) {

        th,
        td {
            padding: 8px;
        }
    }

}

/* SM */
@media screen and (min-width: 576px) {
    .w-sm-50 {
        width: 50% !important;
    }
}

/* XL */
@media screen and (min-width: 1200px) {
    .recupDatesPadding {
        padding-left: 33px !important;

    }
}

.adminTable td p {
    // font-family: pangram, sans-serif !important;
    line-height: 1.5rem;
    vertical-align: middle;
    margin: auto;
}

.adminTableSelMoment td p {
    font-family: pangram, sans-serif !important;
    line-height: 2.2rem;
    vertical-align: middle;
    margin: auto;
}

.adminTable td {
    display: table-cell;
    vertical-align: middle;
}

@import 'assets/css/_colorsite';
//$color1
//$color2

.sousMenuAdmin {
    color: black !important;
}

/* XL */
@media screen and (min-width: 1700px) {
    .sousMenuAdmin {
        width: 92%;
    }
}