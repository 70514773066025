@import 'assets/css/_colorsite';

// ancienne couleur : #802119
// nouvelle couleur :
$newColor: #802119;

.colisContainer {
    margin-top:10%;
}

.colisProductsContainer {
    margin-left: 0;
    margin-right:0
}
.price-designationContainer {
    margin-left: 0em;
    margin-right: 0em;
    width: 250px;;
    height:90px;
    position: absolute;
    flex-wrap: nowrap;
}

.priceContainer {
    border: 3px solid $newColor;
    border-radius: 15px;
    width: auto;
    height:80px;
    background-color: white;
    position: relative;
    bottom: 20px;
    left: 3px;
}

.priceContainerGrosVolumes {
    border: 3px solid $newColor;
    border-radius: 15px;
    width: 6rem;
    height:80px;
    background-color: white;
    position: relative;
    bottom: 20px;

}

.priceContainerColis {
    border: 3px solid $newColor;
    border-radius: 15px;
    width: auto;
    height:80px;
    background-color: white;
    position: relative;
    bottom: 20px;
    left: 3px;
}

.colisDesignation {
    font-family: 'Myriad Pro Black';
    position: relative;
    margin-bottom:15px;
    margin-right:5px;
    /* font-weight: bold; */
    text-align:center;
    letter-spacing: 1px;
}

.temporaireColis {
    font-family: 'Myriad Pro Black';
    font-size: 1.4rem;
    margin-left: 7.8rem;
}

.temporaireGrosVolume {
    font-family: 'Myriad Pro Black';
    font-size: 1.4rem;
    margin-right: -3rem !important;
}

.numeroTemporaire {
    font-family: 'Myriad Pro Black';
    font-size: 1.2rem;
    margin-right: -0.5rem !important;
}

.nameAndPriceGrosVolume {
   width: 15rem;
   margin: auto;
}

.colisDesignationGrosVolume {
    font-family: 'Myriad Pro Black';
    position: absolute;
    margin-bottom:15px;
    margin-right:5px;
    color: white;
    font-weight: bold;
    margin-top: -25px;
    letter-spacing: 1px;
}

.price, .pricePerKg{

    color:$newColor ;
    text-align: center;

}

.price {
    font-family: 'Myriad Pro Bold';
    margin-top:10px;
    font-size:1.5em;
}

.euroPrice {
    font-size: 0.6em;
    font-weight:900;
}

.pricePerKg {
    font-family: 'Myriad Pro Semibold';
    font-size: 0.8em;
    font-style: italic;
    font-weight: 800;
}

.img-qty-kartContainer {
    margin-top:30%;
}

/* XS */
@media screen and (max-width: 575px){ 

} 
/* SM */
@media screen and (min-width: 576px) and (max-width: 767px){ 

} 
/* MD */
@media screen and (min-width: 768px) and (max-width: 991px){ 
    
} 
/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px){ 
    .lineH {
        line-height: 1.7rem !important;
    }
} 
/* XL */
@media screen and (min-width: 1200px) { 

} 

.qty-kartContainerColis {
    // position: absolute;
    max-width:222px;
    z-index: 1;
    bottom: 0;
}

.colisImage {
    width: 220px;
    height:260px;
    z-index: 0;
    position: absolute;
}

/* XS */
@media screen and (max-width: 575px){ 
    .imageGrosVolume {
        width: 90% !important;
        max-width: 400px !important;
        height: auto !important;
        max-height: 260px !important;
        z-index: 0;
        position: absolute;
    }
    .price-designationContainerGrosVolumes {
        background-color: $newColor;
        margin-left: 0em;
        margin-right: 0em;
        width: 100% !important;
        height:90px;
        position: relative !important;
        flex-wrap: nowrap;
    }
} 
 
.imageGrosVolume {
    width: 440px;
    height:410px;
    z-index: 0;
    position: absolute;
}

.oneProductContainer {
    margin-top: 10%;
    background-color: white;
    border: 1px solid $newColor;
    height: 350px;
    width: 250px;
    min-width: 250px;
}

.oneProductContainerColis {
    margin-top: 10%;
    background-color: white;
    border: 1px solid $newColor;
    // height: 110%;
    width: 379px;
    min-width: 250px;
}

.oneProductHeight {
    height: 480px;
}

.marginTopProductsColis {
    margin-top: 11%;
    margin-left: 2rem !important;
}

// .oneProductHeight2 {
//     height: 410px;
// }

.muchProductsMl {
    margin-left: 1.5rem;
}

.descriptionGrosVolumes {
    margin-top: 30rem;
}

.oneProductContainerGrosVolumes {
    margin-top:10%;
    background-color: white;
    border: 1px solid $newColor;
    max-height:500px;
    min-height: 350px;
    width:510px;
    min-width:250px;
}

.price-designationContainer {
    background-color: $newColor;
    max-width: 250px;
}

.price-designationContainerColis {
    background-color: $newColor;
    margin-left: 0em;
    margin-right: 0em;
    width: 378px;
    height:90px;
    position: absolute;
    flex-wrap: nowrap;
}

.price-designationContainerGrosVolumes {
    background-color: $newColor;
    margin-left: 0em;
    margin-right: 0em;
    width: 510px;
    height:90px;
    position: absolute;
    flex-wrap: nowrap;
}

.colisDesignation {
    color: white;
    font-weight: bolder;
    font-size:1.1em;
    right:5px;
}

.colisDesignation:hover {
    color: #f6a9a7;
}
.qty-kartContainer {
    justify-content: right;
    height:262px;
    max-width:222px;
    position: relative;
    z-index: 1;
    margin-bottom: 0vh;
}

.ajouterPanier {
    border: none;
    background-color: #FAE8D9;
    width: 10rem;
    color: rgb(0, 0, 0);
    font-size: 1rem;
    padding: 7px;
    border-radius: 20px;
}

.lowerButton, .counter, .upperButton {
    height:25px;
    width:25px;

}

.counter {
    text-align: center;
    border-top:1px solid $newColor;
    border-bottom: 1px solid $newColor;
    background-color: white;
    color:$newColor;
    font-weight: bolder;
}

.lowerButton, .upperButton {
    border: 1px solid $newColor;
    background-color: black;
    color:white;
    font-weight: bolder;

}

.whiteGradient {
    height:55px;
    width:300px;
    background: linear-gradient(to right,  transparent, rgba(255, 255, 255, 0.8)50%);
}

.whiteGradientColis {
    height:55px;
}

.kartItem {
    height:40px;
    margin-left: 15px;
    margin-right: 10px;
}

.kartItemColis {
    height:40px;
    margin-left: 5px;
}

.colisInsertContainer {
    margin-top: 50px;
    margin-left: 0;
}

.colisInsertContainer p {
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
}

.insertTextContainer {
    padding: 10px 0;
}

.thinkAboutIt {
    max-height:5vh;
}

.insertFirstP {
    margin-bottom: 10px !important;
}

@media screen and (max-width: 1420px) {
    .colisContainer {
        max-width: 95% !important;
    }
}

@media screen and (min-width:1010px) and (max-width: 1185px) {
    .colisContainer, .colisInsertContainer {
        flex-wrap: wrap;
        width: 80%;
    }
}

@media screen and (max-width:991px) {
    .colisContainer {
        align-items: center;
        margin: auto;
        max-width:95%
    }

    .colisProductsContainer {
        margin: auto;
    }

    .colisInsertContainer {
        justify-content: center;
        margin: auto;
        margin-top: 20px;
        max-width: 95%;
    }

    .thinkAboutIt {
        margin-top: 20px;
        max-width: 100%;
    }
}

@media screen and (max-width:530px) {
    .colisProductsContainer {
        flex-direction: column !important;
        // align-items: center !important;
    }

}

.colisIcon {
    position: absolute;
    // margin-top: -12.7rem;
    // margin-left: -3rem;
    margin-top: 6.1rem;
    margin-left: -20.3rem;
    // margin-top: 3.1rem;
    // margin-left: 20rem;
}

.filtreRougeHorizontal {
    border: 2px solid white;
    // width: 50rem;
}

.filtreRougeHorizontal:hover {
    border: 2px solid rgb(173, 97, 97);
}

/* LG */
@media screen and (min-width: 992px) and (max-width: 1199px){ 
    .filtreRougeHorizontal {
        font-size: 14px;
    }
}

.img-qty-kartContainerColis {
    margin-top:27.5%;
    height: 58%;
    display: flex !important;
    align-items: center !important;
    line-height: 2rem;
}

.img-qty-kartContainerGrosVolumes {
    margin-top:27.5%;
    height: 58%;
    display: flex !important;
    align-items: center !important;
    line-height: 2rem;
}

.corpsColis {
    font-size: 1.2rem;
    // display: flex !important;
    align-items: end !important;
    bottom: 0 !important;
}

/* XS -- */
@media screen and (max-width: 467px){ 
    .corpsColis {
        width: 360px !important;
    }
    .colisProductsContainer {
        margin: 0 !important;
    }
} 

/* XS */
@media screen and (max-width: 575px){ 
    .img-qty-kartContainerGrosVolumes {
        margin-top:5%;
        height: 58%;
        display: flex !important;
        align-items: center !important;
        line-height: 2rem;
    }
    .descriptionGrosVolumes {
        margin-top: 22rem !important;
        position: absolute !important;
    }
} 
